import { getRegion } from '@/utils/utils'
import env from '../../env'
import i18n from 'taro-i18n'
/**
 * 处理请求Url
 * @param {*} route 路由
 */
const mergeBaseUrl = (route) => {
  console.log('ENV:', i18n.t.locale, env)

  if (route.startsWith('http')) {
    return route
  }

  let baseUrl = 'https://api.ypkshop.com/'

  if (env === 'prod') {
    if (window.location.host == 'h5.uchoice.pro') {
      baseUrl =
        i18n.t.locale === 'vi' || getRegion() === 'vn'
          ? 'https://api.vn.uchoice.pro/'
          : 'https://api.th.uchoice.pro/'
    } else {
      baseUrl = 'https://api.ypkshop.com/'
    }
  }
  if (env === 'dev') {
    baseUrl =
      getRegion() === 'vn' ? 'https://api.dev.vn.youpik.dev/' : 'https://api.dev.th.youpik.dev/'
  }

  if (env === 'test') {
    // baseUrl = 'https://api.test.youpik.dev/'
    window.location.pathname == '/pages/lazadaVoucher/index'
      ? (baseUrl = 'https://youpik.test.catike.com/')
      : (baseUrl =
        getRegion() === 'vn'
          ? 'https://api.test.vn.youpik.dev/'
          : 'https://api.test.th.youpik.dev/')
  }

  if (env === 'pre') {
    baseUrl =
      getRegion() === 'vn' ? 'https://api.pre.vn.uchoice.pro' : 'https://api.pre.th.uchoice.pro'
  }

  let fullUrl = baseUrl + route
  return fullUrl
}

export const newH5Url = () => {
  switch (env) {
    case 'dev':
      return 'https://h5.dev.uchoice.youpik.dev'
    case 'test':
      return 'https://h5.test.uchoice.youpik.dev'
    case 'pre':
      return 'https://h5.pre.uchoice.pro'
    default:
      return 'https://h5.prod.uchoice.pro'
  }
}

export default mergeBaseUrl
