import utils from '@/utils/utils'

const distEnObj = {
  'Youpik Ultimate Application': 'Youpik Ultimate Application',
  '自购省钱，分享赚钱': 'Shop to save, share to earn',
  '请用您的手机自带的默认浏览器打开该链接，并点击下载按钮下载app':
    'Please open this link with your phone’s default browser and click the download button.',
  '安卓用户-点击下方按钮下载': 'Android users - click the button below to download',
  下载到其他类型的安卓手机: 'Download on other types of Android devices',
  '您在下载app到安卓手机过程中可能遇到的问题：':
    'The problems you may meet during downloading at Android:',
  '1. app被自动删除': '1. The app is deleted automatically.',
  '如果您的安卓手机是OPPO、三星或其他类型，在您点击下载App之前，我们建议您先关闭google play protected。 否则，下载后，该应用程序将被 Google Play Protect 自动删除。':
    'If your android phone is OPPO, Samsung or other types, before you click to download the App, we recommend you to close the google play protect first. Otherwise, after downloading, the app will be automatically deleted by Google play protect.',
  如何关闭谷歌play保护: 'How to close the Google Play protect',
  '2. 遇到关于app的警告提示': '2. Meet warnings about this App.',
  '(1)如果您在下载app过程中，收到如下图所示的警告信息，只需点击"查看更多细节" >> "仍要下载"':
    '(1) If you see the warning as shown below, click "More details" >> "Install anyway".',
  '(2)如果您打开app时收到如下图所示的警告信息，只需点击“打开应用程序”按钮即可查看。':
    '(2) If you see the warning as shown below, click the "Open App" button to view it.',
  以上问题都不是: 'None of the above issues,',
  联系客服: 'Contact Customer Service >>',
  '苹果用户-点击下方按钮下载': 'IOS users - click the button below to download',
  '想了解更多关于Youpik的官网消息和活动 ?': `Want to know more about Youpik's official news?`,
  '想了解更多关于uChoice的官网消息和活动 ?': `Want to know more about uChoice's official news?`,
  ceartoeUseUchoice: 'Creators use uChoice pro to get ',
  请点击按钮或扫码下方二维码添加官方账号进行了解:
    'Please click the button or scan the QR code below to add the official Line account to learn more.',
  'Go to "Add friends" on LINE and search for our ID, or scan this QR code':
    'Go to "Add friends" on LINE and search for our ID, or scan this QR code',
  '如何关闭谷歌play保护 ?': 'How to close the Google Play protect ?',
  'Method 1 - Close it in Play store': 'Method 1 - Close it in Play store',
  '1. Open the "Play store" App >> Click your head portrait.':
    '1. Open the "Play store" App >> Click your head portrait.',
  '2. Click "Play Protect" to turn off it.': '2. Click "Play Protect" to turn off it.',
  'Method 2 - Settings in the phone': 'Method 2 - Settings in the phone.',
  'Path - 1': `Path - 1
  1. Open the "Settings" app on your device.
  2. In Settings, scroll down and find the "Apps" or "Apps & Notifications" option.
  3. Click "Apps" or "Apps & Notifications", then find "Google Play Services" in the list.
  4. Click "Google Play Services" and then on the app information screen, you can select the "Deactivate" or "Disable" button.`,
  'Path - 2': `Path - 2
  1. Open the "Settings" app on your device.
  2. In Settings, search "play protect". 
  3. Click "google play protect" >> App security >> Device protection >> off .`,
  '（3）如果您遇到如下图所示的警告信息，忽略即可，不要卸载':
    '(3) If you see the warning as shown below, just ignore it and please do not uninstall it.',
  如何关闭谷歌play保护机制: 'How to close the Google Play protect',
  'Step 1: 打开"Play store" App >> 点击头像': `(1) Open the "Play store" App >> Click your head portrait.`,
  '2. 点击 "Play Protect" ，按钮点击关闭': '(2) Click "Play Protect" to turn off it.',
  '客服电话：02-329-5690 (工作时间：9:00-18:00)': 'CS Tel: 02-329-5690 (9:00-18:00)',
  Contact1: 'CS Tel: 02-329-5929',
  Contact2: '(9A.M. - 6P.M.  Except Public Holidays)'
}

const distThObj = {
  'Youpik Ultimate Application': 'แอปพลิเคชัน Youpik Ultimate',
  '自购省钱，分享赚钱': 'ยิ่งช้อป ยิ่งแชร์ ยิ่งชวน ยิ่งได้',
  '请用您的手机自带的默认浏览器打开该链接，并点击下载按钮下载app':
    'เปิดลิงก์นี้ด้วยบราวเซอร์ในโทรศัพท์ของคุณแล้วคลิกปุ่มดาวน์โหลด',
  '安卓用户-点击下方按钮下载': 'ผู้ใช้ Android - คลิกปุ่มด้านล่างเพื่อดาวน์โหลด',
  下载到其他类型的安卓手机: 'ดาวน์โหลดบนอุปกรณ์ Android ประเภทอื่น',
  '您在下载app到安卓手机过程中可能遇到的问题：': 'ปัญหาที่อาจพบเจอระหว่างการดาวน์โหลดบน Android',
  '1. app被自动删除': '1.แอปอาจถูกลบอัตโนมัติ',
  '如果您的安卓手机是OPPO、三星或其他类型，在您点击下载App之前，我们建议您先关闭google play protected。 否则，下载后，该应用程序将被 Google Play Protect 自动删除。':
    'หากโทรศัพท์ Android ของคุณคือ OPPO , Samsung หรือประเภทอื่น ๆ เราขอแนะนำให้คุณปิดการป้องกัน Google Play ก่อนดาวน์โหลดแอป มิฉะนั้นหลังจากการดาวน์โหลด แอปจะถูกลบโดยอัตโนมัติโดย Google Play Protect',
  如何关闭谷歌play保护: 'วิธีปิด Google Play Protect',
  '2. 遇到关于app的警告提示': '2.พบคำเตือนเกี่ยวกับแอปนี้',
  '(1)如果您在下载app过程中，收到如下图所示的警告信息，只需点击"查看更多细节" >> "仍要下载"':
    '(1) หากคุณได้รับคำเตือนที่แสดงด้านล่างระหว่างการดาวน์โหลด ให้คลิก "รายละเอียดเพิ่มเติม" >> "ติดตั้งต่อไป"',
  '(2)如果您打开app时收到如下图所示的警告信息，只需点击“打开应用程序”按钮即可查看。':
    '(2) หากคุณได้รับคำเตือนที่แสดงด้านล่างเมื่อเปิดแอป ให้คลิกปุ่ม "เปิดใช้แอป" เพื่อดู',
  以上问题都不是: 'หากพบปัญหานอกเหนือจากนี้',
  联系客服: 'ติดต่อฝ่ายบริการ >>',
  '苹果用户-点击下方按钮下载': 'ผู้ใช้ IOS - คลิกปุ่มด้านล่างเพื่อดาวน์โหลด',
  '想了解更多关于Youpik的官网消息和活动 ?': 'ต้องการทราบข่าวสารจาก Youpik เพิ่มเติม',
  '想了解更多关于uChoice的官网消息和活动 ?': 'ต้องการทราบข่าวสารจาก uChoice เพิ่มเติม',
  ceartoeUseUchoice: ' ครีเอเตอร์ทำยอดได้ ',
  请点击按钮或扫码下方二维码添加官方账号进行了解:
    'กรุณาคลิกปุ่มหรือสแกนคิวอาร์โค้ดด้านล่างและแอดบัญชี Line official เพื่อเรียนรู้เพิ่มเติม',
  'Go to "Add friends" on LINE and search for our ID, or scan this QR code':
    'ไปที่ "เพิ่มเพื่อน" บนไลน์แล้วค้นหา ID ของเรา หรือแสกนคิวอาร์โค้ด',
  '如何关闭谷歌play保护 ?': 'ปิด the Google Play protect อย่างไร ?',
  'Method 1 - Close it in Play store': 'วิธีที่ 1 - ปิดใน Play store',
  '1. Open the "Play store" App >> Click your head portrait.':
    '1. เปิดแอป "Play store" >> คลิกที่บัญชีของคุณ',
  '2. Click "Play Protect" to turn off it.': '2. คลิกที่  "Play Protect" เพื่อปิด',
  'Method 2 - Settings in the phone': 'วิธีที่ 2 - ตั้งค่าในโทรศัพท์',
  'Path - 1': `ทางแรก
  1. เปิดแอป "ตั้งค่า" บนเครื่องของคุณ
  2. ในการตั้งค่า เลื่อนหาตัวเลือก "แอป" หรือ "แอปและการแจ้งเตือน"
  3. คลิปเลือก "แอป" หรือ "แอปและการแจ้งเตือน" แล้วหา "บริการ Google Play" ในลิส
  4. คลิก "บริการ Google Play" จากนั้นคุณสามารถเลือกปุ่ม "ปิดใช้งาน" หรือ "ปิดใช้งาน" ได้`,
  'Path - 2': `ทางที่ 2
  1. เปิดแอป "ตั้งค่า" บนเครื่องของคุณ
  2. ในการตั้งค่า ค้นหา "play protect"
  3. คลิก  "google play protect"  >> ความปลอดภัยของแอป >> การป้องกันอุปกรณ์ >> ปิด`,
  '（3）如果您遇到如下图所示的警告信息，忽略即可，不要卸载':
    '(3) หากคุณได้รับคำเตือนที่แสดงด้านล่าง ไม่ต้องถอนการติดตั้ง',
  如何关闭谷歌play保护机制: 'วิธีปิด Google Play Protect',
  'Step 1: 打开"Play store" App >> 点击头像': `(1) เปิดแอป "Play store" >> คลิกที่บัญชีของคุณ`,
  '2. 点击 "Play Protect" ，按钮点击关闭': '(2) คลิกที่  "Play Protect" เพื่อปิด',
  '客服电话：02-329-5690 (工作时间：9:00-18:00)': 'เบอร์ติดต่อ: 02-329-5690 (9:00-18:00)',
  Contact1: 'เบอร์ติดต่อ: 02-329-5929',
  Contact2: '(9A.M. - 6P.M.  ยกเว้นวันหยุดนักขัตฤกษ์)'
}

const distViObj = {
  'Youpik Ultimate Application': 'Ứng dụng Youpik Ultimate',
  '自购省钱，分享赚钱': 'Mua để tiết kiệm, chia sẻ để kiếm tiền',
  '请用您的手机自带的默认浏览器打开该链接，并点击下载按钮下载app':
    'Vui lòng mở liên kết này bằng trình duyệt mặc định của điện thoại và nhấp vào nút tải xuống.',
  '安卓用户-点击下方按钮下载': 'Người dùng Android - nhấp vào nút bên dưới để tải xuống',
  下载到其他类型的安卓手机: 'Tải xuống trên các thiết bị Android khác',
  '您在下载app到安卓手机过程中可能遇到的问题：':
    'Các vấn đề bạn có thể gặp phải khi tải xuống trên Android:',
  '1. app被自动删除': '1. Ứng dụng bị xóa tự động.',
  '如果您的安卓手机是OPPO、三星或其他类型，在您点击下载App之前，我们建议您先关闭google play protected。 否则，下载后，该应用程序将被 Google Play Protect 自动删除。':
    'Nếu điện thoại Android của bạn là OPPO, Samsung hoặc các loại khác, trước khi nhấp vào tải ứng dụng, chúng tôi khuyên bạn nên tắt bảo vệ Google Play trước. Nếu không, sau khi tải xuống, ứng dụng sẽ bị Google Play Protect tự động xóa.',
  如何关闭谷歌play保护: 'Cách tắt bảo vệ Google Play',
  '2. 遇到关于app的警告提示': '2. Gặp cảnh báo về ứng dụng này.',
  '(1)如果您在下载app过程中，收到如下图所示的警告信息，只需点击"查看更多细节" >> "仍要下载"':
    '(1) Nếu bạn thấy cảnh báo như hình dưới, hãy nhấp vào "Xem thêm chi tiết" >> "Cài đặt dù sao".',
  '(2)如果您打开app时收到如下图所示的警告信息，只需点击“打开应用程序”按钮即可查看。':
    '(2) Nếu bạn thấy cảnh báo như hình dưới, hãy nhấp vào nút "Mở ứng dụng" để xem.',
  以上问题都不是: 'Không gặp phải các vấn đề trên,',
  联系客服: 'Liên hệ dịch vụ khách hàng >>',
  '苹果用户-点击下方按钮下载': 'Người dùng iOS - nhấp vào nút bên dưới để tải xuống',
  '想了解更多关于Youpik的官网消息和活动 ?': `Muốn biết thêm về tin tức chính thức của Youpik?`,
  '想了解更多关于uChoice的官网消息和活动 ?': `Muốn biết thêm về tin tức chính thức của uChoice?`,
  ceartoeUseUchoice: 'nhà sáng tạo đã sử dụng uChoice pro',
  请点击按钮或扫码下方二维码添加官方账号进行了解:
    'Vui lòng nhấp vào nút hoặc quét mã QR bên dưới để thêm tài khoản chính thức của Line để biết thêm.',
  'Go to "Add friends" on LINE and search for our ID, or scan this QR code':
    'Đi tới "Thêm bạn bè" trên LINE và tìm kiếm ID của chúng tôi, hoặc quét mã QR này',
  '如何关闭谷歌play保护 ?': 'Cách tắt bảo vệ Google Play ?',
  'Method 1 - Close it in Play store': 'Phương pháp 1 - Tắt nó trong cửa hàng Play',
  '1. Open the "Play store" App >> Click your head portrait.':
    '1. Mở ứng dụng "Cửa hàng Play" >> Nhấp vào hình đại diện của bạn.',
  '2. Click "Play Protect" to turn off it.': '2. Nhấp vào "Play Protect" để tắt nó.',
  'Method 2 - Settings in the phone': 'Phương pháp 2 - Cài đặt trong điện thoại.',
  'Path - 1': `Đường dẫn - 1
  1. Mở ứng dụng "Cài đặt" trên thiết bị của bạn.
  2. Trong Cài đặt, cuộn xuống và tìm tùy chọn "Ứng dụng" hoặc "Ứng dụng & Thông báo".
  3. Nhấp vào "Ứng dụng" hoặc "Ứng dụng & Thông báo", sau đó tìm "Google Play Services" trong danh sách.
  4. Nhấp vào "Google Play Services" và sau đó trên màn hình thông tin ứng dụng, bạn có thể chọn nút "Hủy kích hoạt" hoặc "Vô hiệu hóa".`,
  'Path - 2': `Đường dẫn - 2
  1. Mở ứng dụng "Cài đặt" trên thiết bị của bạn.
  2. Trong Cài đặt, tìm kiếm "bảo vệ chơi". 
  3. Nhấp vào "google play protect" >> Bảo mật ứng dụng >> Bảo vệ thiết bị >> tắt.`,
  '（3）如果您遇到如下图所示的警告信息，忽略即可，不要卸载':
    '(3) Nếu bạn gặp cảnh báo như hình dưới, hãy bỏ qua và đừng gỡ cài đặt.',
  如何关闭谷歌play保护机制: 'Cách tắt bảo vệ Google Play',
  'Step 1: 打开"Play store" App >> 点击头像': `(1) Mở ứng dụng "Cửa hàng Play" >> Nhấp vào hình đại diện của bạn.`,
  '2. 点击 "Play Protect" ，按钮点击关闭': '(2) Nhấp vào "Play Protect" để tắt nó.',
  '客服电话：02-329-5690 (工作时间：9:00-18:00)': 'Số điện thoại CS: 02-329-5690 (9:00-18:00)',
  Contact1: 'Số điện thoại CS: 02-329-5929',
  Contact2: '(9 giờ sáng - 6 giờ chiều, Trừ các ngày lễ công cộng)'
}

export const distEn = utils.localeFormat(distEnObj, 'dist')
export const distTh = utils.localeFormat(distThObj, 'dist')
export const distVi = utils.localeFormat(distViObj, 'dist')
